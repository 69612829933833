.searchscreen{
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
}
.searchheader{
    display: flex;
    height: 7%;
    align-items: center;
    flex-direction: row;
}

.nodata{   
     display: flex;
     height: 70%;
     align-items: center;
     justify-content: center;  
     color: rgb(201, 196, 196); 
     text-align:center; 
     margin-left: 10px;
     margin-right: 10px;
     font-family: var(--font);
     letter-spacing: 1px;
}
.moreloading{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(59, 130, 236);
    font-size: 20px;
}
.moreloading span{
  margin-left: 10px;
}
.booklist{
    display: flex;
    height: 93%;
    overflow: scroll;
    scroll-behavior: smooth;
}
.clearicon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    right: 30px;
    top: 0px;
    padding: 10px;
    font-size: 10px;
    color: #B1B0AF;

}
.book-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.book-item {
    display: flex;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
}

.book-cover {
    width: 100px;  /* Increase width for better visibility */
    height: 150px; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure image fills the frame */
    border-radius: 5px;
    image-rendering: auto; 
    margin-right: 10px;
}

.book-details {
    flex-grow: 1;
}

.book-details p {
    margin-bottom: 5px;
}

.actions button {
    margin-right: 5px;
    cursor: pointer;
}

.author {
    color: #CD4C1F;
}

.title {
    font-weight: 600;
}



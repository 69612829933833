
.loginHead
{
    font-family: var(--font);
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #FF812E;
    letter-spacing: 1px;
    font-weight: bold;

}
.inst{
    font-family: var(--font);
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 15px;
    color: #fff;
    background-color: #FF812E;
    letter-spacing: 1px;
    font-weight: bold;
}
.instwish{
    font-family: var(--font);
    border-radius: 20px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 15px;
    color: #000;
    letter-spacing: 1px;
}
Input{
    font-family: var(--font);
}
.login_continer{
    display:flex;
    height:100vh;
    width:100%;
    /* background-color: #CD4C1F; */
    background-image: linear-gradient(to bottom right, rgba(240, 169, 89, 0),rgb(243, 144, 52));
    /* background-image: linear-gradient(180deg,rgba(240, 169, 89, 0), rgb(243, 144, 52)); */
    /* padding:10px */
}
.login_imgcontiner{
   display:none;
   
}
.loginPage{
    width: 100%;
    padding: 15px;
    margin:0 auto;
    background-color: #fff;
}
.ant-modal-confirm-btns
{
    display: block;
}
.loginDesc
{
    text-align: left;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.loginSign{
    margin-top: 20px;
    flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);

}
.loginButton{
    margin-top: 15px;
    width:100%;
    text-align: center;
    font-family: var(--font);

}
.loginButton img{
    width: 15%;
    max-width: 60px;
    margin: 10px 6px;
    /* padding: 10px; */
    box-shadow: 2px 2px 2px #bbb;
    border-radius: 50px;
}

.loginfoot
{
    margin-top: 30px;
}
.ant-form-item {
    margin-bottom: 10px;
}
.loginBtn
{
    display: flex;
    width: 100%;
    color: #fff;
    background: #CD4C1F;
    border-color: #CD4C1F;
    align-items: center;
    justify-content: center;
    font-family: var(--font);

}
.loginBtn:hover{
    color: #fff;
    background: #ac3f04;
    border-color: #ac3f04;
}

.newgencolor
{
    color: #CD4C1F;
}
.textCenter
{
    text-align: center;
    margin-top: 20px;
}
.ant-form-item .ant-form-item-label {
    padding: 0px;
}
.ant-modal-confirm .ant-modal-body
{
    padding: 20px !important;
}
.login-foot span{
    font-family: var(--font);
    cursor: pointer;
    color: #CD4C1F;
}
.login-foot {
    text-align: center;
    font-family: var(--font);
    font-size: 12px;
    cursor: pointer;
}
.forget-text{
    text-align: center;
    font-family: var(--font);
    font-size: 12px;
    cursor: pointer;
    color: #CD4C1F;
}
.siginlogin{
    text-align: center;
    font-family: var(--font);
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
    color: #CD4C1F;
}
.forget-text:hover{
    color: #CD4C1F;
}
.sigin-foot span{
    cursor: pointer;
    color: #CD4C1F;
}
.forgot-foot span{
    cursor: pointer;
    color: #CD4C1F;

}
.social-login{
    font-family: var(--font);
    cursor: pointer;
    transition: transform .2s; 
   
}
.social-login:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .login_fooder{
    font-family: var(--font);
     display: flex;
      flex-direction:row;
      align-items: center;
     justify-content: space-between;
  }
  .btntext{
      font-family: var(--font);
      letter-spacing: 1px;
      color: white;
  }
  @media only screen and (min-width: 600px) {
    .login_continer{
        display:flex;
        height:100vh;
        width:100%;
        background-image: linear-gradient(to bottom right, rgba(240, 169, 89, 0),rgb(243, 144, 52));
        padding:30px
    }
    .login_imgcontiner{
        
        width:40%;
        background-color:#fff;
        display:flex;
        background-image: url("../../api//images/booklogin.jpg");
        background-size: cover;
        background-position: center;
        align-items: center;
        justify-content: center;
       flex-direction: column;
    }
    .loginPage{
        width: 60%;
        padding: 15px;
        margin:0 auto;
        background-color: #fff;
    }
  }

  @media only screen and (min-width: 768px) {
    .login_continer{
        display:flex;
        height:100vh;
        width:100%;
        background-image: linear-gradient(to bottom right, rgba(240, 169, 89, 0),rgb(243, 144, 52));
        padding:50px;
        
    }
    .login_imgcontiner{
        width:60%;
        background-color:#fff;
        display:flex;
        background-image: url("../../api//images/booklogin.jpg");
        background-size:cover;
        background-position: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px 
    }
    .loginPage{
        width: 40%;
        
        margin:0 auto;
        background-color: #fff;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px ;
    }
  }
  

.colorCircle
{
    padding: 5px 13px;
    border-radius: 50px;
    background-color:"red";
}
.highlightColor1{
    background: #fdfd81;
}
.highlightColor2{
    background: #5dfd8a;
}
.highlightColor3{
    background: #60daf9;
}
.highlightColor4{
    background: #fd6ce2;
}
.ant-modal-content
{
    width:251px !important;
    margin: 0 auto;
}
.ant-card ant-card-bordered
{
    width: 100% !important;
}
.ant-drawer-body {
    flex-grow: 1;
    padding: 0px;
    overflow: auto;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}
.ant-card-bordered{
    border-left: 3px solid red;
    border-bottom: none;
    border-top: none;
    border-right: none;
    margin-bottom: 0px;
}
.ant-drawer-header 
{
    background: #CD4C1F;
}
.ant-drawer-title
{
    color:#fff;
    font-size: 20px !important;
}
.cartDate{
    text-align: right;
    color:#adadad;
    font-size: 12px;
}
.logImg img {
    width:90px;
    margin-bottom: 0px;
    margin-top: 2px;
}
